<template>
  <div class="row mb-3">
    <div class="col-sm-12 card text-left py-3">
      <div class="row pb-2">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-2 text-right">
              <label class="col-form-label font-weight-bold">Fecha:</label>
            </div>
            <div class="col-sm-5 pr-0">
              <date-picker
                class="col-sm-6 px-0"
                v-model="datepicker"
                lang="es"
                format="DD/MM/YYYY"
                type="date"
                value-type="date"
                @input="actualizarModalPagos"
                :disabled="getDisabledDatePicker"
              ></date-picker>
            </div>
          <div class="col-sm-4 px-0 text-center"
            style="float: right;">
            <p v-if="numReserva">Reserva: {{ numReserva }}</p>
            <p v-if="numeroTratamiento || getNumeroItemChecked" :style="{ color: 'red' }">
              Internación: {{ numeroTratamiento || getNumeroItemChecked }}
            </p>
          </div>
          <p v-if="numeroCotizacion">Cot: {{ numeroCotizacion }}</p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-2 px-0">
              <button class="btn" type="button"
                :class="getCotizacionId ? 'btn-success' : 'btn-secondary'"
                :disabled="numeroTratamiento || getNumeroItemChecked ? true : false"
                @click="openModalFiltrarCotizacion">
                Cotizacion
              </button>
              <ThemodalFiltrarCotizacion
              ref="themodalCotizacion"
              />
            </div>
            <div class="col-sm-3 pr-0 pl-4 text-center">
              <template v-for="(modalidad, index) in filteredModalidadesFacturas">
                <button
                  :key="index"
                  @click="changeInvoiceType(index + 1)"
                  v-if="tipo_factura == modalidad.id"
                  style="font-size: 12px;"
                  class="btn btn-block px-0"
                  :class="colorBtnModalidaFactura()"
                  :disabled="isDisabledEstadoFactura"
                >
                  {{ modalidad.nombre }}
                </button>
              </template>
            </div>
                <div class="col-sm-2 text-right">
                  <label class="col-form-label"><b>Moneda:</b></label>
                </div>
                <div class="col-sm-3 px-0">
                  <multiselect id="comboMonedas"
                    v-model="monedaSelected"
                    :options="comboMoneda"
                    @input="onChangeMoneda"
                    placeholder=""
                    :allow-empty="false"
                    :searchable="false"
                    label="name" track-by="id"
                    select-label="" deselect-label="X"
                    :disabled="getStatusComboMoneda || isReserva"
                  >
                    <span slot="caret" v-if="!(monedaSelected===null)"></span>
                    <template slot="singleLabel" slot-scope="props">
                      <small class="itemSelected-combo">{{props.option.name}}</small>
                    </template>
                    <template slot="option" slot-scope="props">
                      <small>{{props.option.name}}</small>
                    </template>
                  </multiselect>
                </div>
                <div class="col-sm-2">
                  <input type="text"
                    v-model="tipoCambio"
                    @change="resetearFacturacion"
                    class="form-control"
                    autocomplete="off"
                    placeholder="T/C"
                    :disabled="isTipoCambioEnabled">
                </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="row mb-2">
            <div class="col-sm-2 px-0 text-right">
              <label class="col-form-label">Paciente:
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-sm-6">
              <multiselect
                v-model="pacienteSelected"
                :options="getPacientes"
                :loading="isLoadingComboPacientes"
                :internal-search="false"
                placeholder=""
                :disabled="getReservaId || numeroTratamiento || getNumeroItemChecked ? true : false"
                open-direction="bottom"
                label="full_name" track-by="id"
                select-label="" deselect-label="X"
                @open="obtenerListaPacientes('')"
                @search-change="busquedaAsincronaPacientes"
                @input="searchPacienteAndShow">
                <span slot="caret" v-if="!(pacienteSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="option" slot-scope="props">
                  {{ props.option.ci }} - {{ props.option.full_name }}
                </template>
                <template slot="afterList">
                  <div class="row">
                    <a
                      v-if="showCargarMas"
                      href="javascript:void(0)"
                      class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
                      @click="obtenerListaPacientes(null)">
                      Cargar mas...
                    </a>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="col-sm-3">
              <div class="row">
                <div class="col-sm-3 px-0">
                  <button
                    class="btn col-sm-11 px-0 py-2"
                    :class="getClassButtonAtajoPaciente"
                    @click="openModalPaciente"
                    title="Paciente"
                  >
                    <i class="i-Add-User h5"></i>
                  </button>
                  <NuevoPacienteModal />
                </div>
                <div class="col-sm-3 px-0" v-if="hasFuncionalidadTratamiento">
                  <button
                    class="btn col-sm-11 px-0 py-2"
                    :class="tratamientosDisponibles? 'btn-success': 'btn-light'"
                    @click="openModalTratamientoEnCurso"
                    title="Internacion"
                  >
                    <i class="i-Check h5"></i>
                  </button>
                  <ModalTratamientoEnCurso />
                </div>
                <div class="col-sm-3 px-0" v-if="hasFuncionalidadOrdenTrabajo">
                  <button class="btn col-sm-11 px-0 py-2"
                    :class="tratamientosDisponibles? 'btn-success': 'btn-light'"
                    @click="openModalTratamiento"
                    :disabled="numeroTratamiento || getNumeroItemChecked ? true : false"
                    title="Orde de Trabajo">
                    <i class="i-Letter-Open h5"></i>
                  </button>
                  <TheModalTratamiento/>
                </div>
                <div class="col-sm-3 px-0" v-if="hasFuncionalidadCalendario">
                  <button class="btn col-sm-11 px-0 py-2"
                    title="Reserva"
                    :class="getClassButtonAtajoReserva"
                    :disabled="getModoEdicion || (numeroTratamiento || getNumeroItemChecked ? true : false)"
                    @click="openModalReserva"
                    >
                    <i class="i-Calendar-4 h5"></i>
                  </button>
                  <TheModalReservaVue :disabled="numeroTratamiento || getNumeroItemChecked ? true : false"
                  @addReserva='addReserva'/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2  px-0 text-right">
              <label class="col-form-label">Facturar a:</label>
            </div>
            <div class="col-sm-4">
              <input type="text"
               v-model="razonSocial"
               class="form-control"
               autocomplete="off" placeholder="Razon Social"
               :disabled="isRazonSocialInputDisabled">
            </div>
            <div class="col-sm-3 pl-1 text-center">
              <input type="text"
                v-model="nit"
                @keyup="verificarNit"
                class="form-control"
                autocomplete="off"
                placeholder="NIT"
                :disabled="isNitInputDisabled"
                @keydown="validarCaracter"
              >
                <small v-show="showMsg" class="text-info">{{msg}}</small>
            </div>
            <div class="col-sm-2" v-show="isCIPaciente">
              <input type="text" class="form-control" placeholder="Comp." id="complement"
                v-model="complemento">
            </div>
            <div class="col-sm-1 pt-1" v-if="showDatoFactura">
              <input id="check_detalle_factura"
               class="form-check-input"
               type="checkbox"
               v-b-popover.hover.top="'Guarda el dato de factura.'"
               v-model="datoFactura"
               :disabled="isTipoFacturaSinFactura">
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-5 pt-2">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-6 pr-0">
                      <div class="col-md-12 px-0" v-if="isDisableSeguro">
                        <input id="checkVelorCero"
                          class="form-check-input"
                          type="checkbox"
                          :disabled="isTipoFacturaConFactura || !checkHabilityValorCero"
                          v-model="checkValorCero"
                          @change="onChangeValorCero"
                        >
                        <label class="form-check-label" for="checkVelorCero">
                          Valor 0
                        </label>
                      </div>
                      <div class="col-md-12 px-0" v-if="isDisableSeguro">
                        <input id="checkSeguroEstado"
                          class="form-check-input"
                          type="checkbox"
                          :disabled="disabledSeguroMedico"
                          v-model="checkSeguroEstado"
                          @change="onChangeSeguroMedico"
                        >
                        <label class="form-check-label seguro" for="checkSeguroEstado">
                          Seguro Medico
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-6 px-0">
                      <input type="text"
                        v-model="nroFactura"
                        class="form-control"
                        placeholder="N° de Factura"
                        autocomplete="off"
                        :readonly="isReadOnlyNroFactura"
                        @click.ctrl="setFacturaManual"
                      >
                    </div>
                    <div class="col-sm-12 px-0">
                      <multiselect
                        v-if="isDisableSeguro"
                        class="mt-1"
                        v-model="aseguradoraSelected"
                        :options="getComboAseguradora"
                        :disabled="!getCheckSeguroEstado || getModoEdicion"
                        @input="onChangeComboAseguradora"
                        placeholder=""
                        label="nombre"
                        track-by="id"
                        select-label=""
                        deselect-label="X">
                        <span slot="caret" v-if="!(aseguradoraSelected===null)"></span>
                        <span slot="noResult">Sin Resultados</span>
                        <template slot="singleLabel" slot-scope="props">
                          <small>{{props.option.nombre}}</small>
                        </template>
                        <template slot="option" slot-scope="props">
                          <small>{{props.option.nombre}}</small>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row pt-2" v-if="hasPermissionMedicoTercero">
                <div class="col-sm-3 px-0 text-right">
                  <label class="col-form-label" style="font-size:12px"><b>Factura Terceros:</b></label>
                </div>
                <div class="col-sm-9">
                  <multiselect v-model="medicoSelected"
                    :options="getMedicos"
                    :disabled="isDisabledMedicoInput || checkHability || isReserva"
                    @input="onChangeMedicoSelected"
                    placeholder=""
                    label="nombre"
                    track-by="id"
                    select-label=""
                    deselect-label="X"
                  >
                    <span slot="caret" v-if="!(medicoSelected===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                    <template slot="singleLabel" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                    <template slot="option" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import privilegios from '@/plugins/privilegios/index';
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { util } from '@/plugins/util';
import { MODALIDAD_FACTURACION, NOMBRE_MODALIDAD_FACTURACION, TIPO_DOCUMENTOS } from '@/utils/globalConventions';
import NuevoPacienteModal from './TheNuevoPacienteModal.vue';
import ModalTratamientoEnCurso from './TheModalTratamientoEnCurso.vue';
import TheModalTratamiento from './TheModalTratamiento.vue';
import TheModalReservaVue from './TheModalReserva.vue';
import ThemodalFiltrarCotizacion from './ThemodalFiltrarCotizacion.vue';

export default {
  name: 'FormFactura',
  components: {
    Multiselect,
    DatePicker,
    NuevoPacienteModal,
    ModalTratamientoEnCurso,
    TheModalTratamiento,
    TheModalReservaVue,
    ThemodalFiltrarCotizacion,
  },
  data() {
    // para los datos del formulario se esta usando vuex"
    return {
      showMsg: false,
      msg: 'Se Guardara este Dato.',
      delayTimeout: null,
      medicoDosificacion: false,
      isDisableSeguro: false,
    };
  },
  mixins: [privilegios],
  async created() {
    this.$nextTick(() => {
      this.$root.$emit('pacienteSelected', this.pacienteSelected);
    });
    const funcionalidadId = this.funcionalidadAccions.SEG_LISTAR.funcionalidad_id;
    const funcionalidadAccion = this.funcionalidadAccions.SEG_LISTAR.accion;
    this.isDisableSeguro = await this.existePrivilegios(funcionalidadId, funcionalidadAccion);
    if (this.getModoEdicion === false
      && (
        this.tipo_factura === MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA
        || this.tipo_factura === MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA
      )
    ) {
      this.validacionSucursalFacturador(this.getSucursal);
    }
  },
  watch: {
    tipo_factura(newVal) {
      if (
        newVal === MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA
        || newVal === MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA
      ) {
        if (this.getModoEdicion === false) {
          this.validacionSucursalFacturador(this.getSucursal);
          this.datepicker = new Date(moment());
        }
      } else {
        this.tipoContingenciaSelected = null;
      }
    },
    tipoEmisionSelected(newVal) {
      if (newVal.cod_clasificador === 2) {
        this.tipoContingenciaSelected = this.tipoContigenciasComputed.find((el) => (
          el.cod_clasificador === 2
        ));
      }
    },
    pacienteSelected(newValue) {
      this.$root.$emit('pacienteSelected', newValue);
      if (newValue && newValue.type_document_id !== TIPO_DOCUMENTOS.CI) {
        this.complemento = null;
      }
    },
  },
  methods: {
    resetearFacturacion() {
      this.setNroFactura(null);
      this.resetDatosAddServiceModal();
      this.resetTableAndAddService();
      this.resetDetalleFactura();
      this.resetDatosFormaPagoModal();
      this.resetFormaPagoFacturaTercero();
    },
    resetValorCero() {
      this.checkValorCero = false;
      this.checkHability = false;
    },
    actualizarResetear() {
      this.resetearFacturacion();
      this.resetValorCero();
      if (this.isTipoFacturaSinFactura) {
        this.checkSeguroEstado = false;
        this.checkHabilityValorCero = true;
        this.razonSocial = null;
        this.nit = null;
        this.datoFactura = false;
      } else {
        this.checkHabilityValorCero = false;
      }
    },
    actualizarModalPagos() {
      this.setInputDiasCredito(0);
      this.actualizarFechaModalPagos();
    },
    actualizarFechaModalPagos() {
      if (this.datepicker === null) {
        return;
      }
      const tempFecha = this.formatearDate(this.datepicker, '-', 'ymd');
      this.setFechaCreditoVencimiento(this.formatearFecha(tempFecha));
    },
    async searchPacienteAndShow() {
      this.resetAjustesModal();
      if (this.pacienteSelected === null) {
        this.showCortinaInformativa = false;

        if (this.getModoEdicion === false) {
          this.checkSeguroEstado = false;
          this.onChangeSeguroMedico();
        }
        return;
      }
      if (this.existeElPaciente(this.pacienteSelected.full_name)) {
        this.showCortinaInformativa = true;
        await this.getDatosPacienteSelected(this.pacienteSelected.id);
      } else {
        this.showCortinaInformativa = false;
      }
      this.obtenerTratamientosPendientes();
      if (this.getModoEdicion === false) {
        if (
          this.getPacienteToShow.tipo_seguro === 'Conjunto'
          && this.getPacienteToShow.aseguradora_id != null
        ) {
          this.checkSeguroEstado = true;
          this.aseguradoraSelected = this.getComboAseguradora.find((el) => (
            el.id === this.getPacienteToShow.aseguradora_id
          ));
          this.onChangeSeguroMedico();
        } else {
          this.checkSeguroEstado = false;
          this.onChangeSeguroMedico();
        }
      }
    },
    existeElPaciente(paciente) {
      const res = this.getPacientes.filter((el) => el.full_name === paciente).length;
      return res;
    },
    formatearDate(fecha, separador, modo = 'any') {
      const year = fecha.getFullYear();
      const month = fecha.getMonth() + 1;
      const day = fecha.getDate();
      if (modo === 'ymd') {
        return `${year}${separador}${month}${separador}${day}`;
      }
      return `${day}${separador}${month}${separador}${year}`;
    },
    formatearFecha(fechaYMD) {
      const fechaTemp = fechaYMD.split('-');
      const year = fechaTemp[0];
      const month = fechaTemp[1];
      const day = fechaTemp[2];
      return `${day}/${month}/${year}`;
    },
    actualizarTipoCambio() {
      this.setTC(this.tipoCambio);
    },
    onChangeMoneda() {
      this.actualizarTipoCambio();
      this.resetearFacturacion();
    },
    async verificarNit() {
      if (this.nit === null) {
        return;
      }
      this.nit = this.nit.trim();
      if (this.pacienteSelected === null || this.nit === '') {
        return;
      }
      if (await this.verificarNitRepetido()) {
        this.showMsg = true;
        this.datoFactura = true;
        this.showDatoFactura = true;
      } else {
        this.showMsg = false;
        this.datoFactura = false;
      }
    },
    async onChangeMedicoSelected() {
      if (this.medicoSelected === null) {
        this.checkFacturaTercero = false;
        this.medicoDosificacion = false;
        this.resetearFacturacion();
        if (this.tipo_factura === MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA
          || this.tipo_factura === MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA) {
          this.validacionSucursalFacturador(this.getSucursal);
          this.datepicker = new Date(moment());
        }
        const LENGTH_MODALIDAD = this.filteredModalidadesFacturas.length;
        this.tipo_factura = this.filteredModalidadesFacturas[LENGTH_MODALIDAD - 1].id;
        return;
      }
      if (this.getModoEdicion === false) {
        this.tipo_factura = null;
        this.checkFacturaTercero = true;
        this.medicoDosificacion = await this.tieneMedicoFacturaTercero();
        if (
          this.medicoDosificacion === true
          && this.medicoSelected.configuracion_factura_tercero === 1
        ) {
          this.checkSeguroEstado = false;
          this.aseguradoraSelected = null;
          this.facturaNumeroManual = false;
          this.tipo_factura = MODALIDAD_FACTURACION.COMPUTARIZADA;
        } else if (this.tipo_factura !== MODALIDAD_FACTURACION.MANUAL) {
          this.checkSeguroEstado = false;
          this.aseguradoraSelected = null;
          this.modalidadFacturaMedico();
        }
        this.resetearFacturacion();
      }
    },
    modalidadFacturaMedico() {
      if (this.medicoSelected.modalidad_facturacion_medico_id) {
        // ELECTRONICA
        if (this.medicoSelected.modalidad_facturacion_medico_id === 2
          && this.extrafacturaElectronica
        ) {
          if (this.medicoSelected.modalidad_id === 1 /* viene de facturador. 1: EL, 2: CL  */) {
            this.tipo_factura = MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA;
          }
          if (this.medicoSelected.modalidad_id === 2 /* viene de facturador. 1: EL, 2: CL  */) {
            this.tipo_factura = MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA;
          }
        } else {
          // util.showNotify('Su empresa no cuenta con la modalidad Electronica en linea', 'error');
          this.tipo_factura = this.filteredModalidadesFacturas[0].id;
        }
        // COMPUTARIZADA
        if (this.medicoSelected.modalidad_facturacion_medico_id === MODALIDAD_FACTURACION.COMPUTARIZADA
          && this.medicoDosificacion === true
        ) {
          this.tipo_factura = MODALIDAD_FACTURACION.COMPUTARIZADA;
        }
        return;
      }
      if (this.medicoSelected.is_factura_manual) {
        if (this.isBloqueadoFacturaManual
          && this.medicoSelected.is_sin_factura
        ) {
          this.tipo_factura = MODALIDAD_FACTURACION.SIN_FACTURA;
        } else {
          this.tipo_factura = MODALIDAD_FACTURACION.MANUAL;
        }
        return;
      }
      if (this.medicoSelected.is_sin_factura) {
        this.tipo_factura = MODALIDAD_FACTURACION.SIN_FACTURA;
      }
    },
    busquedaAsincronaPacientes(query) {
      this.temporaPageComboPacientes = 1;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.obtenerListaPacientes(query), DELAY);
    },
    onChangeSeguroMedico() {
      if (this.checkSeguroEstado === false) {
        this.aseguradoraSelected = null;
      }
      this.resetearFacturacion();
    },
    onChangeComboAseguradora() {
      this.resetearFacturacion();
      this.setSeguroDobleSelected(null);
    },
    setFacturaManual() {
      if (this.checkFacturaTercero === false && this.getModoEdicion === false) {
        this.facturaNumeroManual = true;
      }
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    openModalPaciente() {
      if (this.isBloqueadoCrearNuevoPaciente) {
        return;
      }
      this.$bvModal.show('modal-nuevoPaciente');
    },
    openModalTratamientoEnCurso() {
      if (this.tratamientosDisponibles) {
        if (this.cierreConfirmado === false && this.islistaTablaVacia) {
          this.$bvModal.show('modal-tratamientoEnCurso');
        } else {
          util.showNotify('No puede agregar un tratamiento porque selecciono otro servicio.', 'warn');
        }
      }
    },
    onChangeValorCero() {
      this.medicoSelected = null;
      this.aseguradoraSelected = null;
      this.onChangeMedicoSelected();
      if (this.checkValorCero && this.isTipoFacturaSinFactura) {
        this.checkHability = true;
        this.checkSeguroEstado = true;
      } else {
        this.resetValorCero();
        this.checkSeguroEstado = false;
      }
    },
    changeInvoiceType(nextIndex) {
      if (this.getModoEdicion === true) {
        return;
      }
      if (this.filteredModalidadesFacturas.length === 1) {
        return;
      }
      if (nextIndex < this.filteredModalidadesFacturas.length) {
        this.tipo_factura = this.filteredModalidadesFacturas[nextIndex].id;
      } else {
        this.tipo_factura = this.filteredModalidadesFacturas[0].id;
      }
      this.actualizarResetear();
    },
    colorBtnModalidaFactura() {
      let color = 'btn-dark';
      if (this.isTipoFacturaComputarizada) {
        color = 'btn-success';
      }
      if (this.isTipoFacturaManual) {
        color = 'btn-warning';
      }
      if (this.isTipoFacturaComputarizadaEnLinea || this.isTipoFacturaElectronicaEnLinea) {
        color = 'btn-electronica_computarizada';
      }
      if (this.isTipoPreVenta) {
        color = 'btn-preventa';
      }
      return color;
    },
    async validacionSucursalFacturador(item) {
      if (this.extrafacturaElectronica) {
        let puntoVentaFiltered = null;
        if (!item.siat_sucursal_id) {
          this.$util.showNotify('La sucursal Seleccionada no esta sincronizada con facturador', 'warn');
          return;
        }
        puntoVentaFiltered = this.getPuntoVentas.filter((el) => (
          el.siat_sucursal_id === item.siat_sucursal_id
        ));
        if (puntoVentaFiltered.length === 0) {
          this.$util.showNotify('La sucursal Seleccionada no esta vinculado con un punto de venta', 'warn');
          return;
        }
        const data = {
          siat_sucursal_id: item.siat_sucursal_id,
          siat_puntoventa_id: puntoVentaFiltered.length > 0 ? puntoVentaFiltered[0].siat_tipo_punto_venta_id : '',
          origen: 1, // 1: ERP, 2: MEDICO TERCERO
          medico_id: null,
        };
        if (this.checkFacturaTercero) {
          if (this.medicoSelected.configuracion_factura_tercero === 0) {
            if (
              this.medicoSelected.siat_sucursal_id !== null
              && this.medicoSelected.siat_punto_venta_id !== null
            ) {
              data.siat_sucursal_id = this.medicoSelected.siat_sucursal_id;
              data.siat_puntoventa_id = this.medicoSelected.siat_punto_venta_id;
              data.origen = 2; // 1: ERP, 2: MEDICO TERCERO
              data.medico_id = this.medicoSelected.id;
            }
          }
        }
        await this.getLeyendasFacturador(data);
        this.synFacturaCreate(data);
      }
    },
    ...mapMutations('formFactura', [
      'setNroFactura',
      'setTC',
    ]),
    ...mapMutations('formaPagoModal', [
      'setInputDiasCredito',
      'setFechaCreditoVencimiento',
    ]),
    ...mapMutations('detalleFactura', [
      'setSeguroDobleSelected',
    ]),
    ...mapActions('formFactura', [
      'obtenerListaPacientes',
      'getDatosPacienteSelected',
      'verificarNitRepetido',
      'tieneMedicoFacturaTercero',
      'obtenerTratamientosPendientes',
      'synFacturaCreate',
      'getLeyendasFacturador',
    ]),
    ...mapActions('addServiceModal', [
      'resetDatosAddServiceModal',
    ]),
    ...mapActions('tableAndAddService', [
      'resetTableAndAddService',
    ]),
    ...mapActions('formaPagoModal', [
      'resetDatosFormaPagoModal',
      'resetAjustesModal',
    ]),
    ...mapActions('formaPagoFacturaTercero', [
      'resetFormaPagoFacturaTercero',
    ]),
    ...mapActions('detalleFactura', [
      'resetDetalleFactura',
    ]),
    openModalTratamiento() {
      this.$bvModal.show('modal-tratamiento');
    },
    openModalReserva() {
      this.$bvModal.show('modal-reserva');
    },
    async addReserva(data) {
      this.numeroReserva = data.numero_reserva;
      const MEDICO_TERCERO = data.medico_tercero;
      if (MEDICO_TERCERO) {
        // this.onChangeMedicoSelected();
        // this.tipo_factura = null;
        this.checkFacturaTercero = true;
        // this.medicoDosificacion = await this.tieneMedicoFacturaTercero();
        if (
          this.medicoDosificacion === true
          && this.medicoSelected.configuracion_factura_tercero === 1
        ) {
          this.checkSeguroEstado = false;
          this.aseguradoraSelected = null;
          this.facturaNumeroManual = false;
          this.tipo_factura = MODALIDAD_FACTURACION.COMPUTARIZADA;
        } else if (this.tipo_factura !== MODALIDAD_FACTURACION.MANUAL) {
          this.checkSeguroEstado = false;
          this.aseguradoraSelected = null;
          this.modalidadFacturaMedico();
        }
      }
    },
    async openModalFiltrarCotizacion() {
      const RESULT_OK = await this.getDataCotizacion();
      if (RESULT_OK) {
        this.$bvModal.show('modalFiltrarCotizacion');
      }
    },
    ...mapActions('modalFiltrarCotizacion', [
      'getDataCotizacion',
    ]),
  },
  computed: {
    ...mapFields('facturacion', [
      'showCortinaInformativa',
    ]),
    ...mapFields('formFactura', [
      'checkFacturaTercero',
      'datepicker',
      'pacienteSelected',
      'isLoadingComboPacientes',
      'razonSocial',
      'nit',
      'showDatoFactura',
      'datoFactura',
      'checkSeguroEstado',
      'nroFactura',
      'aseguradoraSelected',
      'monedaSelected',
      'comboMoneda',
      'tipoCambio',
      'medicoSelected',
      'temporaPageComboPacientes',
      'totalSearchPacientes',
      'facturaNumeroManual',
      'tratamientosDisponibles',
      'checkHability',
      'checkValorCero',
      'checkHabilityValorCero',
      'tipo_factura',
      'modalidadesFacturas',
      'tipoContingencias',
      'tipoContingenciaSelected',
      'tipoEmisions',
      'tipoEmisionSelected',
      'cafc',
      'numeroReserva',
      'complemento',
      'indentificacionSelected',
      'numeroTratamiento',
    ]),
    ...mapFields('modalCierreTratamiento', [
      'cierreConfirmado',
    ]),
    ...mapFields('main', [
      'funcionalidadAccions',
      'extrafacturaElectronica',
    ]),
    ...mapGetters('main', [
      'hasPermissionMedicoTercero',
      'hasFuncionalidadTratamiento',
      'hasFuncionalidadOrdenTrabajo',
      'hasFuncionalidadCalendario',
      'hasPermisionListarConsultaExterna',
    ]),
    filteredModalidadesFacturas() {
      const commonItems = [];
      if (this.getModoEdicion) {
        return this.modalidadesFacturas;
      }
      // if (
      //   this.checkFacturaTercero
      //   && this.medicoDosificacion === false
      //   && this.isBloqueadoFacturaManual
      // ) {
      //   return this.modalidadesFacturas.filter((mod) => (
      //     mod.id === MODALIDAD_FACTURACION.SIN_FACTURA
      //   ));
      // }

      if (this.checkFacturaTercero
        && this.medicoSelected) {
        // MODALIDAD MANUAL DEL MEDICO
        if (this.medicoSelected.modalidad_facturacion_medico_id === 1) {
          // modalidad computarizada del medico
          const modalidadComputarizada = {
            id: MODALIDAD_FACTURACION.COMPUTARIZADA,
            nombre: NOMBRE_MODALIDAD_FACTURACION.COMPUTARIZADA,
          };
          commonItems.push(modalidadComputarizada);
        }
        if (this.medicoSelected.modalidad_facturacion_medico_id === 2
          && this.extrafacturaElectronica
        ) {
          // modalidad electronica del medico
          if (this.medicoSelected.modalidad_id === 1 /* viene de facturador. 1: EL, 2: CL  */) {
            const modalidadElectronica = {
              id: MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA,
              nombre: this.medicoSelected.modalidad,
            };
            commonItems.push(modalidadElectronica);
          }
          if (this.medicoSelected.modalidad_id === 2 /* viene de facturador. 1: EL, 2: CL  */) {
            const modalidadComputarizadaLinea = {
              id: MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA,
              nombre: this.medicoSelected.modalidad,
            };
            commonItems.push(modalidadComputarizadaLinea);
          }
        }
        if (this.medicoSelected.is_factura_manual) {
          const modalidadManual = {
            id: MODALIDAD_FACTURACION.MANUAL,
            nombre: NOMBRE_MODALIDAD_FACTURACION.MANUAL,
          };
          commonItems.push(modalidadManual);
        }
        if (this.medicoSelected.is_sin_factura) {
          const modalidadSinFactura = { id: MODALIDAD_FACTURACION.SIN_FACTURA, nombre: 'Sin Factura' };
          commonItems.push(modalidadSinFactura);
        }
        return commonItems;
      }

      if (this.checkFacturaTercero) {
        return this.modalidadesFacturas.filter((mod) => (
          mod.id !== MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA
          && mod.id !== MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA
        ));
      }
      return this.modalidadesFacturas;
    },
    isDisabledMedicoInput() {
      if (this.isBloqueadoMedicoTerceros) {
        return true;
      }
      if (this.getModoEdicion) {
        return true;
      }
      if (this.checkFacturaTercero || this.checkSeguroEstado) {
        return true;
      }
      return false;
    },
    isTipoCambioEnabled() {
      return (this.tipoCambio === null || this.getModoEdicion);
    },
    isReadOnlyNroFactura() {
      if (this.isTipoFacturaSinFactura) {
        return true;
      }
      if (this.facturaNumeroManual) {
        return false;
      }
      return (
        this.getModoEdicion
        || !this.checkFacturaTercero
        || this.tipo_factura !== MODALIDAD_FACTURACION.MANUAL
      );
    },
    showCargarMas() {
      const isOkToSearch = this.temporaPageComboPacientes * 100 < this.totalSearchPacientes;
      return this.totalSearchPacientes > 100 && isOkToSearch;
    },
    isDisabledEstadoFactura() {
      if (this.isBloqueadoVentaSinFactura) {
        return true;
      }
      return this.getDisabledStatusFactura || this.checkSeguroEstado;
    },
    isDisabledCheckSeguro() {
      if (this.isBloqueadoAseguradora) {
        return true;
      }
      return this.getModoEdicion || this.checkFacturaTercero || this.isTipoFacturaSinFactura;
    },
    showContingencia() {
      return (
        (this.isTipoFacturaComputarizadaEnLinea)
        || (this.isTipoFacturaElectronicaEnLinea)
      );
    },
    isRazonSocialInputDisabled() {
      return this.isTipoFacturaSinFactura;
    },
    isNitInputDisabled() {
      return this.isTipoFacturaSinFactura;
    },
    tipoContigenciasComputed() {
      if (this.checkFacturaTercero) {
        if (this.medicoSelected.configuracion_factura_tercero === 0) {
          return this.medicoSelected.siat_tipo_factura_contingencias;
        }
      }
      return this.tipoContingencias;
    },
    getClassButtonAtajoPaciente() {
      if (this.isBloqueadoCrearNuevoPaciente || this.pacienteSelected) {
        return 'btn-light';
      }
      return 'btn-success';
    },
    getClassButtonAtajoReserva() {
      return 'btn-success';
    },
    ...mapGetters('main', [
      'isBloqueadoFacturaManual',
      'isBloqueadoVentaSinFactura',
      'isBloqueadoAseguradora',
      'isBloqueadoCrearNuevoPaciente',
      'isBloqueadoMedicoTerceros',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
      'getSucursal',
      'getPuntoVentas',
      'getPacienteToShow',
    ]),
    ...mapGetters('formFactura', [
      'getDisabledStatusFactura',
      'getDisabledDatePicker',
      'getStatusComboMoneda',
      'getPacientes',
      'getCheckSeguroEstado',
      'getComboAseguradora',
      'getMedicos',
      'isTipoFacturaSinFactura',
      'isTipoFacturaConFactura',
      'isTipoFacturaComputarizada',
      'isTipoFacturaManual',
      'isTipoFacturaComputarizadaEnLinea',
      'isTipoFacturaElectronicaEnLinea',
      'isTipoPreVenta',
    ]),
    ...mapGetters('tableAndAddService', [
      'islistaTablaVacia',
    ]),
    ...mapGetters('modalReserva', [
      'getReservaId',
      'getIsFacturaTerceroReserva',
      'getIsReservaClinica',
    ]),
    ...mapGetters('modalTratamientoEnCurso', [
      'getNumeroItemChecked',
    ]),
    isReservaClinica() {
      if (this.getReservaId && this.getIsReservaClinica) {
        return true;
      }
      return false;
    },
    isReservaFacturaTercero() {
      if (this.getReservaId && this.getIsFacturaTerceroReserva) {
        return true;
      }
      return false;
    },
    disabledSeguroMedico() {
      if (this.getReservaId && this.isReservaClinica) {
        return true;
      }
      return this.isDisabledCheckSeguro && !this.checkHability;
    },
    isReserva() {
      if (this.getReservaId) {
        return true;
      }
      return false;
    },
    numReserva() {
      const numero = this.getReservaId ? this.numeroReserva : null;
      return numero;
    },
    isCIPaciente() {
      return this.indentificacionSelected && this.indentificacionSelected.id === TIPO_DOCUMENTOS.CI;
    },
    ...mapGetters('modalFiltrarCotizacion', [
      'getCotizacionId',
      'getNumeroCotizacion',
    ]),
    numeroCotizacion() {
      const numero = this.getCotizacionId ? this.getNumeroCotizacion : null;
      return numero;
    },
  },
};
</script>

<style scoped>
.itemSelected-combo{
  padding-right: 0px;
  position: absolute;
  width: 100%;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  font-style: normal;  /* Asegura que la fuente no sea cursiva */
}

.btn-electronica_computarizada {
  background-color: #00a8bc;
  color: white;
}
.btn-preventa {
  background-color: #009493;
  color: white;
}
@media (max-width: 1187px) {
  .itemSelected-combo, .seguro {
    font-size: 11px;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
