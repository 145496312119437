<template>
  <b-modal
    id="modalFiltrarCotizacion"
    size='custom-lg-max'
    title="Filtrar Cotizaciones"
    header-class="pb-2"
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <div class="container row">
        <div class="col-sm-4 pl-0">
          <h5 class="modal-title">Filtrar Cotizaciones</h5>
        </div>
        <div class="col-sm-5">
          <template>
            <b-alert show variant="success" v-if="disponible">
              Tiene disponible
            </b-alert>
          </template>
          <template v-if="errors.length > 0">
            <b-alert show variant="warning">
              No disponible
              <div class="col-sm-12" :key="'li'+(index+1)" v-for="(error, index) in errors">
                <span>{{error.producto_nombre}} </span> <p>{{error.descripcion}}</p>
              </div>
            </b-alert>
          </template>
          <!-- <template v-if="!mensajeError">
            <b-alert show variant="danger">
              <strong>Alerta: Configuracion de Factura {{(modalidaId == 1) ? 'Computarizada': 'Manual'}}</strong> : <br>
              {{mensajeError}} {{(modalidaId == 1) ? ' Computarizada.': ' Manual.'}}
            </b-alert>
          </template> -->
        </div>
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            placeholder="Buscar producto"
          >
        </div>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="close()"
      >×</button>
    </template>
    <div class="row mb-5">
      <div class="col-sm-12 px-2">
        <b-table
          class="table table-bordered"
          :fields="fields"
          :items="listaTablaModal"
          show-empty
        >
          <template #cell(fecha)="row">
            {{ getFechaFormat(row.item.fecha, 'DD/MM/YYYY') }}
          </template>
          <template #cell(importe)="row">
            {{row.item.importe | numeral('0,0.00')}} {{row.item.simbolo}}
          </template>
          <template #cell(estado_nombre)="row">
            <button class="btn font-weight-bold"
            :style="{'background-color': row.item.estado_color}" >{{row.item.estado_nombre}}</button>
          </template>
          <template #cell(probar)="row">
            <button class="btn btn-block py-2"
              :class="(row.item.hayStock === true)? 'btn-success' : 'btn-orange'"
              :id="'btn-'+row.item.id"
              @click="getDataServiceCotizacion(row.item, row.index)"
              >
              Probar
            </button>
          </template>
          <template #empty="">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong v-if="isBusy && listaTablaModal.length == 0" class="text-warning">Cargando...</strong>
              <strong v-else class="text-warning">No hay registros para mostrar.</strong>
            </div>
          </template>
          <template #emptyfiltered="">
            <div class="text-center my-2">
              <strong class="text-warning">No se ha encontrado registros para su búsqueda.</strong>
            </div>
          </template>
        </b-table>
        <div class="row">
          <div class="col-sm-8">
          </div>
          <div class="col-sm-4" v-if="listaTablaModal.length > 0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              @input="getDataCotizacion"
              class="my-0 float-right"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row border-light">
      <div class="col-sm-12 text-right pt-3">
        <button class="btn btn-secondary mr-2" type="button" @click="cancelModal()">
          Cancelar
        </button>
        <button class="btn btn-success" type="button" @click="saveModal()">
          Guardar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { util } from '@/plugins/util';

export default {
  name: 'modalFiltrarCotizacion',
  data() {
    return {
      fields: [
        {
          key: 'numero', label: 'N°', thClass: 'table-secondary', thStyle: { width: '7%' },
        },
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '13%' },
        },
        {
          key: 'cliente', label: 'Nombre', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'forma_pago', label: 'F/Pago', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '12%' },
        },
        {
          key: 'vendedor', label: 'Vendedor', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '15%' },
        },
        {
          key: 'estado_nombre', label: 'Estado', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'importe', label: 'Importe', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '12%' },
        },
        {
          key: 'usuario', label: 'Usuario', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '14%' },
        },
        {
          key: 'probar', label: 'Probar', class: 'text-center', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
      ],
    };
  },
  methods: {
    cancelModal() {
      this.$bvModal.hide('modalFiltrarCotizacion');
    },
    async saveModal() {
      if (!this.cotizacion) {
        util.showNotify('Debe seleccionar una cotizacion', 'warn');
        return;
      }
      this.listaTabla = [];
      await this.busquedaAsincronaPacientes(this.cotizacion.paciente);
      this.setCotizacionId(this.cotizacion.id);
      this.setNumeroCotizacion(this.cotizacion.numero);
      this.getDatosPacienteSelected(this.pacienteSelected.id);
      this.clearParamasListaTabla();
      const dataAddReserva = {
        numero_reserva: this.cotizacion.numero,
      };

      this.actualizarInterfaz();
      this.setActividadEconomicaId(this.cotizacion.actividad_economica_id);
      this.setNroFactura(this.cotizacion.numero_factura);
      this.$bvModal.hide('modalFiltrarCotizacion');
      this.$emit('addCotizacion', dataAddReserva);
      this.cotizacion = null;
    },
    ...mapActions('modalFiltrarCotizacion', [
      'getDataCotizacion',
      'getDataService',
    ]),
    getFechaFormat(dateString, formato) {
      return moment(dateString).format(formato);
    },
    getDataServiceCotizacion(item, index) {
      const DTO = {
        id: item.id,
        sucursal_id: this.getSucursal ? this.getSucursal.id : null,
        modalidad_id: this.tipo_factura,
        index,
      };
      this.getDataService(DTO);
    },
    async busquedaAsincronaPacientes(query) {
      await this.obtenerListaPacientes(query);
      if (this.getPacientes.length > 0) {
        const PACIENTE_SELECTED = this.getPacientes[0];
        this.pacienteSelected = PACIENTE_SELECTED;
      }
    },
    ...mapMutations('addServiceModal', [
      'setActividadEconomicaId',
      'setDocumentoSectorId',
      'setSiatTipoFacturaId',
    ]),
    ...mapMutations('formFactura', [
      'setNroFactura',
      'setTC',
      'cargarMonedaSelectedById',
    ]),
    clearParamasListaTabla() {
      const DETALLES = this.cotizacion ? this.cotizacion.detalles : [];
      DETALLES.forEach((element) => {
        const DATA = {
          numero: element.numero,
          servicio_id: element.tipo_servicio_id,
          descripcion: element.descripcion,
          medicoTempSelected: null,
          cantidad: element.cantidad,
          precio_unitario: element.precio_unitario,
          total: parseInt(element.cantidad, 10) * parseFloat(element.precio_unitario, 10),
          servicio: element.servicio,
          seguro: 0,
          fecha_turno: null,
          hora_turno: null,
          numero_ticket: null,
          numero_turno: null,
          is_turno_manual: false,
          siat_actividad_economica_id: element.siat_actividad_economica_id,
          siat_descripcion_service: element.siat_descripcion_service,
          siat_descripcion_unidad_medida: element.siat_descripcion_unidad_medida,
          siat_tipo_producto_service_id: element.siat_tipo_producto_service_id,
          siat_unidad_medida_id: element.siat_unidad_medida_id,
          idRow: element.id,
          tipo_atencion_id: element.tipo_atencion_id,
          medicoDerivadorSelected: null,
          actividad_economica_id: element.actividad_economica_id,
          descuento: 0,
        };
        this.setDocumentoSectorId(element.documento_sector_id);
        this.setSiatTipoFacturaId(element.tipo_documento_id);
        this.listaTabla.push(DATA);
      });
    },
    actualizarInterfaz() {
      this.cargarMonedaSelectedById(this.cotizacion.moneda_id);
      this.onChangeMoneda();
      this.actualizarTotalSeguro();
      this.actualizarTotalSeguroDoble();
      this.resetDatosFormaPagoModal();
      this.actualizarSubTotal();
      this.resetAjustesModal();
      this.listaBadges = [];
    },
    ...mapActions('detalleFactura', [
      'actualizarSubTotal',
      'actualizarTotalSeguro',
      'actualizarTotalSeguroDoble',
    ]),
    ...mapActions('formaPagoModal', [
      'resetDatosFormaPagoModal',
      'resetAjustesModal',
    ]),
    ...mapActions('formFactura', [
      'obtenerListaPacientes',
      'getDatosPacienteSelected',
    ]),
    ...mapMutations('modalFiltrarCotizacion', [
      'setNumeroCotizacion',
      'setCotizacionId',
    ]),
    actualizarTipoCambio() {
      this.setTC(this.tipoCambio);
    },
    onChangeMoneda() {
      this.actualizarTipoCambio();
      this.resetearFacturacion();
    },
    resetearFacturacion() {
      // this.setNroFactura(null);
      this.resetDatosAddServiceModal();
      this.resetFormaPagoFacturaTercero();
    },
    ...mapActions('addServiceModal', [
      'resetDatosAddServiceModal',
    ]),
    ...mapActions('detalleFactura', [
      'resetDetalleFactura',
    ]),
    ...mapActions('formaPagoFacturaTercero', [
      'resetFormaPagoFacturaTercero',
    ]),
  },
  computed: {
    ...mapState('modalFiltrarCotizacion', [
      'listaTablaModal',
      'perPage',
      'estado',
      'totalItems',
      'isBusy',
      'errors',
      'indexCotizacionAnterior',
      'mensajeError',
    ]),
    ...mapFields('modalFiltrarCotizacion', [
      'currentPage',
      'filter',
      'disponible',
      'delayTimeout',
      'cotizacion',
      'cotizacionId',
      'numeroCotizacion',
    ]),
    ...mapGetters('facturacion', [
      'getSucursal',
    ]),
    ...mapFields('formFactura', [
      'tipo_factura',
      'pacienteSelected',
      'tipoCambio',
    ]),
    ...mapFields('tableAndAddService', ['listaTabla']),
    ...mapFields('detalleFactura', [
      'listaBadges',
    ]),
    ...mapGetters('formFactura', [
      'getPacientes',
    ]),
  },
  watch: {
  },
};
</script>

<style scoped>
.btn-orange {
  color: white;
  background-color: #f4b375;
}
</style>
